import { replace, t } from "translations"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

enum VideoService {
    Vimeo = "vimeo",
    YouTube = "youtube",
    ExorLive = "exorlive"
}

export default function Video({
    id,
    service,
    caption
}: {
    id: string
    service: string
    caption?: string
}) {
    switch (service) {
        case VideoService.Vimeo:
            return (
                <VimeoVideo
                    id={id}
                    caption={caption ?? undefined}
                />
            )
        case VideoService.YouTube:
            return (
                <>
                    <StyledVideo
                        allowFullScreen
                        src={`https://www.youtube.com/embed/${id}?autoplay=1&origin=${process.env.NEXT_PUBLIC_HOST}`}
                        title={id}
                    />
                    {caption && (
                        <StyledCaption variant="caption">
                            {caption}
                        </StyledCaption>
                    )}
                </>
            )
        case VideoService.ExorLive:
            return (
                <>
                    <StyledVideo
                        allowFullScreen
                        src={`https://exorlive.com/video/?ex=${id}`}
                        title={id}
                    />
                    {caption && (
                        <StyledCaption variant="caption">
                            {caption}
                        </StyledCaption>
                    )}
                </>
            )
        default:
            return (
                <div>
                    {replace(t.video.serviceNotSupported, {
                        service: service,
                        id: id
                    })}
                </div>
            )
    }
}

function VimeoVideo({ id, caption }: { id: string; caption?: string }) {
    const src =
        id.indexOf("?") > -1
            ? `https://player.vimeo.com/video/${id}&title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0`
            : `https://player.vimeo.com/video/${id}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0`

    return (
        <>
            <StyledVideo
                allowFullScreen
                src={src}
                title={id}
            />
            {caption && (
                <StyledCaption variant="caption"> {caption}</StyledCaption>
            )}
        </>
    )
}

const StyledVideo = styled("iframe")(({ theme }) => ({
    marginBottom: theme.spacing(2),
    display: "flex",
    border: "none",
    flexGrow: 1,
    width: "100%",
    height: 180,
    [theme.breakpoints.up(400)]: {
        height: 280
    },

    [theme.breakpoints.up("sm")]: {
        height: 325
    },
    [theme.breakpoints.up(750)]: {
        height: 390
    },

    [theme.breakpoints.up("md")]: {
        height: 330
    },

    [theme.breakpoints.up("lg")]: {
        height: 430
    }
}))

const StyledCaption = styled(Typography)({
    display: "block",
    textAlign: "center"
})
